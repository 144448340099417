import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { PeriodType } from '../types';

export const defaultDateFormat = 'YYYY-MM-DD';

export const getDateBySession = (periodValue: string, nrOfSessions?: number) => {
  const session = parseFloat(periodValue.substring(11));
  const date = moment(`${periodValue.substring(0, 10)}`, defaultDateFormat).startOf('day');
  date.add(session, 'seconds');
  if (nrOfSessions) {
    const sessionHours = 24 / nrOfSessions;
    date.add(sessionHours * session, 'hours');
  }
  return date;
};

export const getMomentForLastXPeriodTypes = (periodType: PeriodType, amountOfPeriods: number): Moment => {
  switch (periodType) {
    case PeriodType.Cycle:
      return moment(new Date())
        .startOf('day')
        .subtract(amountOfPeriods * 3, 'weeks');
    case PeriodType.Day:
    case PeriodType.Session:
      return moment(new Date()).startOf('day').subtract(amountOfPeriods, 'days');
    case PeriodType.Quarter:
      return moment(new Date())
        .startOf('day')
        .subtract(amountOfPeriods * 3, 'months');
    case PeriodType.Month:
    case PeriodType.RollingMonth:
      return moment(new Date()).startOf('day').subtract(amountOfPeriods, 'months');
    case PeriodType.Year:
      return moment(new Date()).startOf('day').subtract(amountOfPeriods, 'year');
    case PeriodType.Week:
      return moment(new Date()).startOf('day').subtract(amountOfPeriods, 'weeks');
  }
};

export const PeriodTypeOrder = [
  PeriodType.Session,
  PeriodType.Day,
  PeriodType.Week,
  PeriodType.Cycle,
  PeriodType.Month,
  PeriodType.Quarter,
  PeriodType.Year
];

export const GetLabelByPeriod = (
  periodValue: string,
  periodType: PeriodType,
  short?: boolean,
  translate?: TFunction
) => {
  const date = GetDateByPeriod(periodValue, periodType);
  return GetLabelByDateAndPeriodType(date, periodType, short, translate);
};

export const GetDateByPeriod = (periodValue: string, periodType: PeriodType): Moment => {
  switch (periodType) {
    case PeriodType.Session:
      return GetDateBySession(periodValue);
    case PeriodType.Day:
    case PeriodType.Cycle:
      return moment(periodValue);
    case PeriodType.Week:
      return getDateByWeek(periodValue);
    case PeriodType.Month:
    case PeriodType.RollingMonth:
      return getDateByMonth(periodValue);
    case PeriodType.Quarter:
      return getDateByQuarter(periodValue);
    case PeriodType.Year:
      return moment(`${periodValue}-01-01`);
    default:
      return moment(periodValue);
  }
};

export const GetLabelByDateAndPeriodType = (
  date: Moment,
  periodType = PeriodType.Cycle,
  short = false,
  translate?: TFunction
) => {
  if (periodType === PeriodType.Week) return getLabelByWeek(moment(date), short, translate!);
  if (periodType === PeriodType.Cycle) return getLabelByCycle(moment(date));
  if (periodType === PeriodType.Day) return getLabelByDay(date);
  if (periodType === PeriodType.Month) return getLabelByMonth(date, short);
  if (periodType === PeriodType.RollingMonth) return getLabelRollingMonth(date, short);
  if (periodType === PeriodType.Quarter) return getLabelByQuarter(moment(date), short);
  if (periodType === PeriodType.Session) return getLabelBySession(date);
  if (periodType === PeriodType.Year) return date.format('YYYY');
  return '';
};

const getDateByWeek = (weekperiod: string) => {
  const date = moment()
    .isoWeekYear(parseInt(weekperiod.slice(0, 4), 10))
    .isoWeek(parseInt(weekperiod.slice(-2), 10))
    .endOf('week');
  return date;
};

const getDateByMonth = (periodValue: string) => {
  return moment(`${periodValue.substring(0, 4)}-${periodValue.substring(4, 6)}-01`, defaultDateFormat).endOf('month');
};

const getDateByQuarter = (quarter: string) => {
  const year = quarter.slice(0, 4);
  const quart = quarter.slice(5, 7);
  const month =
    quart === '01' || quart === '1'
      ? '03'
      : quart === '02' || quart === '2'
      ? '06'
      : quart === '03' || quart === '3'
      ? '09'
      : '12';
  const firstDayOfQuarter: Moment = moment(`${year}-${month}-01`, defaultDateFormat);
  const lastDayOfQuarter = firstDayOfQuarter.clone().endOf('month');
  return lastDayOfQuarter;
};

const GetDateBySession = (stringDate: string): Moment => {
  var dateValue = stringDate.slice(0, 10);
  var session = stringDate.slice(10, 12);
  const date: Moment = moment(dateValue).startOf('day');
  date.hour(parseInt(session));
  return date;
};

export const DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const getLastXPeriods = (date: Moment, periodType: PeriodType, numberOfPeriods: number) => {
  let periods = [] as string[];
  for (let i = 0; i < numberOfPeriods; i++) {
    periods.push(getPeriodByDate(substractXPeriodTypeFromDate(date, periodType, i), periodType));
  }
  if (periodType === PeriodType.Session) {
    periods = periods.map((p) => p.substring(0, 10));
  }
  return periods;
};

export const substractXPeriodTypeFromDate = (date: Moment, periodType: PeriodType, X: number) => {
  switch (periodType) {
    case PeriodType.Week:
      return date.clone().subtract(X * 1, 'week');
    case PeriodType.Month:
    case PeriodType.RollingMonth:
      return date.clone().subtract(X * 1, 'month');
    case PeriodType.Quarter:
      return date
        .startOf('day')
        .clone()
        .subtract(X * 3, 'months');
    case PeriodType.Year:
      return date.clone().subtract(X * 1, 'year');
    case PeriodType.Cycle:
      return date.clone().subtract(X * 3, 'weeks');
    case PeriodType.Day:
    case PeriodType.Session:
    default:
      return date.clone().subtract(X * 1, 'day');
  }
};

export const getPeriodByDate = (date: Moment, periodType: PeriodType) => {
  switch (periodType) {
    case PeriodType.Week:
      return getWeek(date);
    case PeriodType.Month:
    case PeriodType.RollingMonth:
      return getMonth(date);
    case PeriodType.Quarter:
      return date.format('YYYY[Q]Q');
    case PeriodType.Year:
      return date.format('YYYY');
    case PeriodType.Session:
      return date.format(defaultDateFormat + ' s');
    case PeriodType.Day:
    case PeriodType.Cycle:
    default:
      return date.format(defaultDateFormat);
  }
};

export const getMonth = (date: Moment) => {
  const startOfWeek = getStartOfMonthMoment(date);
  return `${startOfWeek.format('YYYYMM')}`;
};

export const getStartOfMonthMoment = (date: Moment): Moment => {
  return date.clone().startOf('month');
};

export const getWeek = (date: Moment) => {
  const startOfWeek = getStartOfWeekMoment(date);
  return `${startOfWeek.format('YYYYWW')}`;
};

export const getStartOfWeekMoment = (date: Moment): Moment => {
  return date.clone().startOf('isoWeek');
};

const getLabelByWeek = (date: Moment, short: boolean, translate: TFunction) => {
  var dateMoment = date.clone();
  const week = dateMoment.endOf('isoWeek').isoWeek();
  const formatWeek = week < 10 ? `0${week}` : `${week}`;

  switch (moment.locale()) {
    case 'en':
    case 'en-US':
      return short
        ? `${translate('FirstLetterOfWeek')}${formatWeek}-${dateMoment.format('YY')}`
        : `${translate('FirstLetterOfWeek')}${formatWeek}-${dateMoment.format('YYYY')}`;
    default:
      return short
        ? `${translate('FirstLetterOfWeek')}${formatWeek}-${dateMoment.format('YY')}`
        : `${translate('FirstLetterOfWeek')}${formatWeek}-${dateMoment.format('YYYY')}`;
  }
};

const getLabelByCycle = (date: Moment) => {
  var dateMoment = moment(date).clone();
  return dateMoment.format(getBrowserDateFormat());
};

const getLabelByDay = (date: Moment) => {
  var dateMoment = moment(date).clone();
  return dateMoment.format(getBrowserDateFormat());
};

const getLabelByMonth = (date: Moment, short: boolean) => {
  return moment(date)
    .clone()
    .format(short ? 'MM-YY' : 'MM-YYYY');
};

const getLabelRollingMonth = (date: Moment, short: boolean) => {
  return moment(date)
    .clone()
    .format(short ? 'MM-YY' : 'MM-YYYY');
};

const getLabelByQuarter = (date: Moment, short: boolean) => {
  return moment(date)
    .clone()
    .format(short ? '[Q]Q-YY' : '[Q]Q-YYYY');
};

const getLabelBySession = (date: Moment) => {
  return date.format(`${getBrowserDateFormat()} - H`);
};

export const getBrowserDateFormat = () => {
  let languagesFormat: string;
  let userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  languagesFormat = dateformats[userLocale] || 'DD-MM-YYYY';

  return languagesFormat;
};

const dateformats = {
  'af-ZA': 'YYYY/MM/DD',
  'am-ET': 'DD/MM/YYYY',
  'ar-AE': 'DD/MM/YYYY',
  'ar-BH': 'DD/MM/YYYY',
  'ar-DZ': 'DD-MM-YYYY',
  'ar-EG': 'DD/MM/YYYY',
  'ar-IQ': 'DD/MM/YYYY',
  'ar-JO': 'DD/MM/YYYY',
  'ar-KW': 'DD/MM/YYYY',
  'ar-LB': 'DD/MM/YYYY',
  'ar-LY': 'DD/MM/YYYY',
  'ar-MA': 'DD-MM-YYYY',
  'ar-OM': 'DD/MM/YYYY',
  'ar-QA': 'DD/MM/YYYY',
  'ar-SA': 'DD/MM/YYYY',
  'ar-SY': 'DD/MM/YYYY',
  'ar-TN': 'DD-MM-YYYY',
  'ar-YE': 'DD/MM/YYYY',
  'arn-CL': 'DD-MM-YYYY',
  'as-IN': 'DD-MM-YYYY',
  'az-Cyrl-AZ': 'DD.MM.YYYY',
  'az-Latn-AZ': 'DD.MM.YYYY',
  'ba-RU': 'DD.MM.YYYY',
  'be-BY': 'DD.MM.YYYY',
  'bg-BG': 'DD.MM.YYYY',
  'bn-BD': 'DD-MM-YYYY',
  'bn-IN': 'DD-MM-YYYY',
  'bo-CN': 'YYYY/MM/DD',
  'br-FR': 'DD/MM/YYYY',
  'bs-Cyrl-BA': 'DD.MM.YYYY',
  'bs-Latn-BA': 'DD.MM.YYYY',
  'ca-ES': 'DD/MM/YYYY',
  'co-FR': 'DD/MM/YYYY',
  'cs-CZ': 'DD.MM.YYYY',
  'cy-GB': 'DD/MM/YYYY',
  'da-DK': 'DD-MM-YYYY',
  'de-AT': 'DD.MM.YYYY',
  'de-CH': 'DD.MM.YYYY',
  'de-DE': 'DD.MM.YYYY',
  'de-LI': 'DD.MM.YYYY',
  'de-LU': 'DD.MM.YYYY',
  'dsb-DE': 'DD.MM.YYYY',
  'dv-MV': 'DD/MM/YYYY',
  'el-GR': 'DD/MM/YYYY',
  'en-029': 'MM/DD/YYYY',
  'en-AU': 'DD/MM/YYYY',
  'en-BZ': 'DD/MM/YYYY',
  'en-CA': 'DD/MM/YYYY',
  'en-GB': 'DD/MM/YYYY',
  'en-IE': 'DD/MM/YYYY',
  'en-IN': 'DD-MM-YYYY',
  'en-JM': 'DD/MM/YYYY',
  'en-MY': 'DD/MM/YYYY',
  'en-NZ': 'DD/MM/YYYY',
  'en-PH': 'MM/DD/YYYY',
  'en-SG': 'DD/MM/YYYY',
  'en-TT': 'DD/MM/YYYY',
  'en-US': 'MM/DD/YYYY',
  'en-ZA': 'YYYY/MM/DD',
  'en-ZW': 'MM/DD/YYYY',
  'es-AR': 'DD/MM/YYYY',
  'es-BO': 'DD/MM/YYYY',
  'es-CL': 'DD-MM-YYYY',
  'es-CO': 'DD/MM/YYYY',
  'es-CR': 'DD/MM/YYYY',
  'es-DO': 'DD/MM/YYYY',
  'es-EC': 'DD/MM/YYYY',
  'es-ES': 'DD/MM/YYYY',
  'es-GT': 'DD/MM/YYYY',
  'es-HN': 'DD/MM/YYYY',
  'es-MX': 'DD/MM/YYYY',
  'es-NI': 'DD/MM/YYYY',
  'es-PA': 'MM/DD/YYYY',
  'es-PE': 'DD/MM/YYYY',
  'es-PR': 'DD/MM/YYYY',
  'es-PY': 'DD/MM/YYYY',
  'es-SV': 'DD/MM/YYYY',
  'es-US': 'MM/DD/YYYY',
  'es-UY': 'DD/MM/YYYY',
  'es-VE': 'DD/MM/YYYY',
  'et-EE': 'DD.MM.YYYY',
  'eu-ES': 'YYYY/MM/DD',
  'fa-IR': 'MM/DD/YYYY',
  'fi-FI': 'DD.MM.YYYY',
  'fil-PH': 'MM/DD/YYYY',
  'fo-FO': 'DD-MM-YYYY',
  'fr-BE': 'DD/MM/YYYY',
  'fr-CA': 'YYYY-MM-DD',
  'fr-CH': 'DD.MM.YYYY',
  'fr-FR': 'DD/MM/YYYY',
  fr: 'DD/MM/YYYY',
  'fr-LU': 'DD/MM/YYYY',
  'fr-MC': 'DD/MM/YYYY',
  'fy-NL': 'DD-MM-YYYY',
  'ga-IE': 'DD/MM/YYYY',
  'gd-GB': 'DD/MM/YYYY',
  'gl-ES': 'DD/MM/YYYY',
  'gsw-FR': 'DD/MM/YYYY',
  'gu-IN': 'DD-MM-YYYY',
  'ha-Latn-NG': 'DD/MM/YYYY',
  'he-IL': 'DD/MM/YYYY',
  'hi-IN': 'DD-MM-YYYY',
  'hr-BA': 'DD.MM.YYYY',
  'hr-HR': 'DD.MM.YYYY',
  'hsb-DE': 'DD.MM.YYYY',
  'hu-HU': 'YYYY.MM.DD',
  'hy-AM': 'DD.MM.YYYY',
  'id-ID': 'DD/MM/YYYY',
  'ig-NG': 'DD/MM/YYYY',
  'ii-CN': 'YYYY/MM/DD',
  'is-IS': 'DD.MM.YYYY',
  'it-CH': 'DD.MM.YYYY',
  'it-IT': 'DD/MM/YYYY',
  'iu-Cans-CA': 'DD/MM/YYYY',
  'iu-Latn-CA': 'DD/MM/YYYY',
  'ja-JP': 'YYYY/MM/DD',
  'ka-GE': 'DD.MM.YYYY',
  'kk-KZ': 'DD.MM.YYYY',
  'kl-GL': 'DD-MM-YYYY',
  'km-KH': 'YYYY-MM-DD',
  'kn-IN': 'DD-MM-YYYY',
  'ko-KR': 'YYYY.MM.DD',
  'kok-IN': 'DD-MM-YYYY',
  'ky-KG': 'DD.MM.YYYY',
  'lb-LU': 'DD/MM/YYYY',
  'lo-LA': 'DD/MM/YYYY',
  'lt-LT': 'YYYY.MM.DD',
  'lv-LV': 'YYYY.MM.DD',
  'mi-NZ': 'Dd/MM/YYYY',
  'mk-MK': 'DD.MM.YYYY',
  'ml-IN': 'DD-MM-YYYY',
  'mn-MN': 'YYYY.MM.DD',
  'mn-Mong-CN': 'YYYY/MM/DD',
  'moh-CA': 'MM/DD/YYYY',
  'mr-IN': 'DD-MM-YYYY',
  'ms-BN': 'DD/MM/YYYY',
  'ms-MY': 'DD/MM/YYYY',
  'mt-MT': 'DD/MM/YYYY',
  'nb-NO': 'DD.MM.YYYY',
  'ne-NP': 'MM/DD/YYYY',
  'nl-BE': 'DD/MM/YYYY',
  'nl-NL': 'DD-MM-YYYY',
  'nn-NO': 'DD.MM.YYYY',
  'nso-ZA': 'YYYY/MM/DD',
  'oc-FR': 'DD/MM/YYYY',
  'or-IN': 'DD-MM-YYYY',
  'pa-IN': 'DD-MM-YYYY',
  'pl-PL': 'DD.MM.YYYY',
  'prs-AF': 'DD/MM/YYYY',
  'ps-AF': 'DD/MM/YYYY',
  'pt-BR': 'DD/MM/YYYY',
  'pt-PT': 'DD-MM-YYYY',
  'qut-GT': 'DD/MM/YYYY',
  'quz-BO': 'DD/MM/YYYY',
  'quz-EC': 'DD/MM/YYYY',
  'quz-PE': 'DD/MM/YYYY',
  'rm-CH': 'DD/MM/YYYY',
  'ro-RO': 'DD.MM.YYYY',
  'ru-RU': 'DD.MM.YYYY',
  'rw-RW': 'MM/DD/YYYY',
  'sa-IN': 'DD-MM-YYYY',
  'sah-RU': 'MM.DD.YYYY',
  'se-FI': 'DD.MM.YYYY',
  'se-NO': 'DD.MM.YYYY',
  'se-SE': 'YYYY-MM-DD',
  'si-LK': 'YYYY-MM-DD',
  'sk-SK': 'DD.MM.YYYY',
  'sl-SI': 'DD.MM.YYYY',
  'sma-NO': 'DD.MM.YYYY',
  'sma-SE': 'YYYY-MM-DD',
  'smj-NO': 'DD.MM.YYYY',
  'smj-SE': 'YYYY-MM-DD',
  'smn-FI': 'DD.MM.YYYY',
  'sms-FI': 'DD.MM.YYYY',
  'sq-AL': 'YYYY-MM-DD',
  'sr-Cyrl-BA': 'DD.MM.YYYY',
  'sr-Cyrl-CS': 'DD.MM.YYYY',
  'sr-Cyrl-ME': 'DD.MM.YYYY',
  'sr-Cyrl-RS': 'DD.MM.YYYY',
  'sr-Latn-BA': 'DD.MM.YYYY',
  'sr-Latn-CS': 'DD.MM.YYYY',
  'sr-Latn-ME': 'DD.MM.YYYY',
  'sr-Latn-RS': 'DD.MM.YYYY',
  'sv-FI': 'DD.MM.YYYY',
  'sv-SE': 'YYYY-MM-Dd',
  'sw-KE': 'MM/DD/YYYY',
  'syr-SY': 'Dd/MM/YYYY',
  'ta-IN': 'DD-MM-YYYY',
  'te-IN': 'DD-MM-YYYY',
  'tg-Cyrl-TJ': 'DD.MM.YYYY',
  'th-TH': 'DD/MM/YYYY',
  'tk-TM': 'Dd.MM.YYYY',
  'tn-ZA': 'YYYY/MM/DD',
  'tr-TR': 'DD.MM.YYYY',
  'tt-RU': 'DD.MM.YYYY',
  'tzm-Latn-DZ': 'DD-MM-YYYY',
  'ug-CN': 'YYYY-MM-DD',
  'uk-UA': 'DD.MM.YYYY',
  'ur-PK': 'DD/MM/YYYY',
  'uz-Cyrl-UZ': 'DD.MM.YYYY',
  'uz-Latn-UZ': 'DD/MM YYYY',
  'vi-VN': 'DD/MM/YYYY',
  'wo-SN': 'DD/MM/YYYY',
  'xh-ZA': 'YYYY/MM/DD',
  'yo-NG': 'DD/MM/YYYY',
  'zh-CN': 'YYYY/MM/DD',
  'zh-HK': 'DD/MM/YYYY',
  'zh-MO': 'DD/MM/YYYY',
  'zh-SG': 'DD/MM/YYYY',
  'zh-TW': 'YYYY/MM/DD',
  'zu-ZA': 'YYYY/MM/DD'
};
