import { createContext, useRef } from 'react';

const context = () => {
  return {
    setCacheEntry: (cacheName: string, cacheEntry: string, object: any) => {},
    getCacheEntry: (cacheName: string, cacheEntry: string) => {
      return undefined as any | undefined;
    }
  };
};

export const CacheContext = createContext(context());

export const CacheContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const cache = useRef<{ [cacheName: string]: { [cacheKey: string]: any } }>({});

  const setCacheEntry = (cacheName: string, cacheEntry: string, object: any) => {
    cache.current = { ...cache.current, [cacheName]: { ...(cache.current[cacheName] || {}), [cacheEntry]: object } };
  };

  const getCacheEntry = (cacheName: string, cacheEntry: string) => {
    return (cache.current[cacheName] && cache.current[cacheName][cacheEntry]) || (undefined as any | undefined);
  };

  const value = {
    setCacheEntry,
    getCacheEntry
  };

  return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>;
};
