import { FilterName } from '../types';
import { sortByOrderInArray } from './ArrayHelpers';
import { DaysOfWeek } from './DateHelpers';
import { sortDims } from './DimHelpers';
import { sortOrderLactation } from './LactationHelpers';

const compare = (a: any, b: any, inverted?: boolean) => {
  if (a < b) return inverted ? 1 : -1;
  if (a > b) return inverted ? -1 : 1;
  return 0;
};

export const FilterSortingMethods: { [key in FilterName]: (a: any, b: any, inverted?: boolean) => number } = {
  [FilterName.dim]: sortDims,
  [FilterName.dayOfTheWeek]: (a, b, inverted) => sortByOrderInArray(DaysOfWeek)(a, b, !inverted),
  [FilterName.bull]: compare,
  [FilterName.group]: compare,
  [FilterName.lactation]: (a, b, inverted) => sortByOrderInArray(sortOrderLactation)(a, b, !inverted),
  [FilterName.mainExitType]: compare,
  [FilterName.parlor]: compare,
  [FilterName.sequenceNumber]: (a, b, inverted) => compare(parseInt(a), parseInt(b), inverted),
  [FilterName.session]: compare,
  [FilterName.technician]: compare,
  [FilterName.ageGroup]: compare
};
