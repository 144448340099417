export type ServiceResult<T> = {
  data: T;
  success: boolean;
  message: string;
};

export type Repository<T> = {
  data: T;
  loading: boolean;
  error?: string;
};

export enum DataType {
  Numerator = 'Numerator',
  Lowerbound_CI = 'Lowerbound_CI',
  Denominator = 'Denominator',
  Upperbound_CI = 'Upperbound_CI',
  KpiValue = 'KpiValue',
  Median = 'Median_ClusterOnTime',
  Maximum = 'Maximum_ClusterOnTime',
  Minimum = 'Minimum_ClusterOnTime',
  ThirdQuartile = 'ThirdQuartile_ClusterOnTime',
  FirstQuartile = 'FirstQuartile_ClusterOnTime'
}
