export enum PeriodType {
  Cycle = 'Cycle',
  Month = 'Month',
  RollingMonth = 'RollingMonth',
  Week = 'Week',
  Quarter = 'Quarter',
  Day = 'Day',
  Session = 'Session',
  Year = 'Year'
}
