export const onlyUnique = <T>(value: T, index: number, self: T[]) => {
  return self.indexOf(value) === index;
};

export const sortByProperty = (a: any, b: any, property: string, asc = true) => {
  var aProp = a[property];
  var bProp = b[property];

  if (aProp < bProp) return asc ? -1 : 1;
  if (aProp > bProp) return asc ? 1 : -1;
  return 0;
};

export const sortByOrderInArray = <T>(array: T[]) => {
  return (a: T, b: T, asc = true) => {
    var aIndex = array.indexOf(a) === -1 ? (asc ? 9999 : -1) : array.indexOf(a);
    var bIndex = array.indexOf(b) === -1 ? (asc ? 9999 : -1) : array.indexOf(b);

    if (aIndex < bIndex) {
      return asc ? -1 : 1;
    } else if (aIndex > bIndex) {
      return asc ? 1 : -1;
    } else {
      return 0;
    }
  };
};

export const EnumToArray = (enumme: any) => {
  return Object.keys(enumme).map((key) => enumme[key]);
};

export const SortArrayByNumericProperty = (property: string) => {
  return (a: any, b: any) => {
    let aIndex = Number(a[property]);
    let bIndex = Number(b[property]);

    if (isNaN(aIndex)) {
      aIndex = Number.MAX_VALUE;
    }
    if (isNaN(bIndex)) {
      bIndex = Number.MAX_VALUE;
    }

    if (aIndex < bIndex) {
      return -1;
    }
    if (aIndex > bIndex) {
      return 1;
    }
    return 0;
  };
};

export const SortArrayByStringProperty = (property: string) => {
  return (a: any, b: any) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  };
};
