import { sortByOrderInArray } from './ArrayHelpers';

export const SSISDimOrder = ['D060', 'D61120', 'D121200', 'D201305', 'D305+'];
export const DimDisplayNameOrder = [
  'Overall',
  'DIM 0 - 60',
  'DIM 61 - 120',
  'DIM 121 - 200',
  'DIM 201 - 305',
  'DIM 305+'
];

export const sortDims = (a: string, b: string, asc = true) => {
  if (SSISDimOrder.includes(a)) {
    return sortByOrderInArray([...DimDisplayNameOrder, ...SSISDimOrder])(a, b, asc);
  }

  var aStartValue: number | undefined = undefined;
  if (a.includes('-') || a.includes(' '))
    aStartValue = parseInt(
      a
        .split('-')[0]
        .replace(/[^0-9\.]+/g, '')
        .replace(/[^\w]/g, '')
    );
  else aStartValue = parseInt(a);

  var bStartValue: number | undefined = undefined;
  if (b.includes('-') || b.includes(' '))
    bStartValue = parseInt(
      b
        .split('-')[0]
        .replace(/[^0-9\.]+/g, '')
        .replace(/[^\w]/g, '')
    );
  else bStartValue = parseInt(b);

  if (aStartValue > bStartValue) {
    return 1;
  } else {
    return -1;
  }
};
