export enum UnitOfMeasure {
  Absolute = 'Absolute',
  Kg = 'Kg',
  KgMin = 'KG/min',
  Percentage = 'Percentage',
  Second = 'Second',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Currency = 'Currency',
  Pounds = 'Pounds',
  LbsMin = 'Lbs/min'
}

export enum WeightUnit {
  Pounds = 'Pounds',
  Kg = 'KG'
}
export const timeUnitsOfMeasure = ['Hour', 'Minute', 'Second'];
export const Pounds = 2.20462262;
