import { Moment } from 'moment';
import { PeriodType } from './TimeTypes';
import { UnitOfMeasure } from './UnitTypes';

export enum PerfectValueString {
  HigherIsBetter = 'HigherIsBetter',
  Closest = 'Closest',
  LowerIsBetter = 'LowerIsBetter'
}

export type KpiInformation = {
  kpiCode: string;
  ssisKpiCode: number;
  category: string;
  name: string;
  perfectValue: PerfectValueString;
  unitOfMeasure: UnitOfMeasure;
};

export interface KpiFilters {
  lactation?: string;
  dim?: string;
  bull?: string;
  technician?: string;
  sequenceNumber?: string;
  dayOfTheWeek?: string;
  parlor?: string;
  group?: string;
  session?: string;
  ageGroup?: string;
  mainExitType?: string;
}

export interface KpiValueFilterOptionsFilter extends KpiFilters {
  herdIds: number[];
  kpiCodes: string[];
  periodType?: string;
  startDate?: Moment;
  endDate?: Moment;
}

export interface KpiValue extends KpiFilters {
  herdId: number;
  kpiCode: string;
  periodValue: string;
  periodType: PeriodType;
  value: string;
  minimum?: string;
  firstQuartile?: string;
  median?: string;
  thirdQuartile?: string;
  maximum?: string;
  numerator?: string;
  denominator?: string;
  lowerbound_CI?: string;
  upperbound_CI?: string;
  zeroToFifteenSec?: string;
  fifteenToThirtySec?: string;
  thirtyToSixtySec?: string;
  sixteenToOneHundredAndTwentySec?: string;
}

export enum PerfectValue {
  Closest,
  Highest,
  Lowest
}

export enum KpiSettingsType {
  User = 'User',
  Herd = 'Herd',
  Group = 'Group',
  Dealer = 'Dealer',
  Corporate = 'Corporate',
  DDW = 'DDW'
}

export interface KpiRule extends KpiFilters {
  kpiCode: string;
  perfectValue?: PerfectValue;
  recommendedValue?: number;
  lowerWarning?: number;
  lowerAlert?: number;
  upperWarning?: number;
  upperAlert?: number;
  absoluteLowerWarning?: number;
  absoluteLowerAlert?: number;
  absoluteUpperWarning?: number;
  absoluteUpperAlert?: number;
  exclude?: boolean;
  hide?: boolean;
  sourceType?: KpiSettingsType;
  sourceName?: string;
  sourceId?: number;
  farmGoal?: number;
}

export type KpiSettings = {
  settingId: number;
  kpiSettingsType: KpiSettingsType;
  priority: string[];
  kpiRules: KpiRule[];
};

export interface KpiValueFilterOptions extends KpiValueOptionsFilterNames {
  periodTypes?: string[];
  filterCombinations?: string[];
}

export enum FilterName {
  lactation = 'lactation',
  dim = 'dim',
  mainExitType = 'mainExitType',
  parlor = 'parlor',
  group = 'group',
  session = 'session',
  bull = 'bull',
  technician = 'technician',
  sequenceNumber = 'sequenceNumber',
  dayOfTheWeek = 'dayOfTheWeek',
  ageGroup = 'ageGroup'
}

export enum FilterOptionKeys {
  lactations = 'lactations',
  dimOptions = 'dimOptions',
  mainExitTypes = 'mainExitTypes',
  parlors = 'parlors',
  groups = 'groups',
  sessions = 'sessions',
  bulls = 'bulls',
  technicians = 'technicians',
  sequenceNumbers = 'sequenceNumbers',
  daysOfTheWeek = 'daysOfTheWeek',
  ageGroups = 'ageGroups'
}

type KpiValueFilterNames = {
  [key in FilterName]?: string;
};

type KpiValueOptionsFilterNames = {
  [key in FilterOptionKeys]?: string[];
};

export interface KpiValueFilter extends KpiValueFilterNames {
  herdIds: number[];
  kpiCodes: string[];
  periodType: PeriodType;
  selectedFilter?: FilterName;
  startDate?: string;
  endDate?: string;
}

export const FilterOptionsMapping: { [key in FilterOptionKeys]: FilterName } = {
  [FilterOptionKeys.lactations]: FilterName.lactation,
  [FilterOptionKeys.dimOptions]: FilterName.dim,
  [FilterOptionKeys.bulls]: FilterName.bull,
  [FilterOptionKeys.technicians]: FilterName.technician,
  [FilterOptionKeys.sequenceNumbers]: FilterName.sequenceNumber,
  [FilterOptionKeys.daysOfTheWeek]: FilterName.dayOfTheWeek,
  [FilterOptionKeys.parlors]: FilterName.parlor,
  [FilterOptionKeys.groups]: FilterName.group,
  [FilterOptionKeys.mainExitTypes]: FilterName.mainExitType,
  [FilterOptionKeys.sessions]: FilterName.session,
  [FilterOptionKeys.ageGroups]: FilterName.ageGroup
};

export type Lactation = 'L0' | 'L0+' | 'L1' | 'L1+' | 'L2' | 'L2+' | 'L3+';

export type KpiValueAsObject = {
  [kpiCode: string]: KpiValue;
};

export interface KpiValue {
  kpiCode: string;
  periodValue: string;
  periodType: PeriodType;
  value: string;
  minimum?: string;
  firstQuartile?: string;
  median?: string;
  thirdQuartile?: string;
  maximum?: string;
  numerator?: string;
  denominator?: string;
  lowerbound_CI?: string;
  upperbound_CI?: string;
  zeroToFifteenSec?: string;
  fifteenToThirtySec?: string;
  thirtyToSixtySec?: string;
  sixteenToOneHundredAndTwentySec?: string;
  [FilterName.lactation]?: Lactation;
  [FilterName.dim]?: string;
  [FilterName.parlor]?: string;
  [FilterName.group]?: string;
  [FilterName.session]?: string;
  [FilterName.bull]?: string;
  [FilterName.technician]?: string;
  [FilterName.sequenceNumber]?: string;
  [FilterName.dayOfTheWeek]?: string;
  [FilterName.mainExitType]?: string;
}
