export enum DRUTranslations {
  Kg = 'Kg',
  Lbs = 'Lbs',
  DayShort = 'DayShort',
  MonthShort = 'MonthShort',
  HourShort = 'HourShort',
  MinuteShort = 'MinuteShort',
  SecondShort = 'SecondShort',
  KgMin = 'kg/min',
  LbsMin = 'lbs/min'
}
